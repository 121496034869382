import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import rehypeReact from "rehype-react";
import { makeStyles } from "@material-ui/core/styles";

import { getSrc } from "gatsby-plugin-image";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextInfoContent from "@mui-treasury/components/content/textInfo";

import Layout from "../components/Layout";

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 654,
    margin: 30,
  },
  heading: {
    maxWidth: 654,
    marginTop: 30,
  },
  content: {
    padding: 24,
  },
  helloImg: {
    objectFit: "contain",
  },
}));
const InfoPage = ({
  data: {
    allMarkdownRemark: { edges: info },
    hello: { edges: hello },
  },
}) => {
  const cardStyles = useStyles();
  return (
    <Layout pageName="info">
      <Helmet>
        <title>Travel Guide</title>
      </Helmet>
      <Container sx={{ mt: 5 }} align="justify" maxWidth="md">
        <Box sx={{ p: 2 }} align="center">
          <Typography marginTop="10px" variant="h5" component="div">
            Here are some of the things you may be wondering about
          </Typography>
          <Typography marginTop="10px" variant="p" component="div">
            For any questions please call or text Aimee Cruz at 619-361-8571.
            Gracias!
          </Typography>
        </Box>
        <hr />
        {info.map((c) => (
          <Box sx={{ pt: 5 }}>
            <TextInfoContent
              overline={c.node.frontmatter.overline}
              heading={c.node.frontmatter.title}
              body={renderAst(c.node.htmlAst)}
            />
          </Box>
        ))}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={1}
        >
          {hello.map(({ node: c }, index) => (
            <Grid item key={index}>
              <CardMedia
                className={cardStyles.helloImg}
                image={getSrc(c.childImageSharp)}
                component="img"
                height="450"
                alt="meet the founder"
              />
            </Grid>
          ))}
        </Grid>
        <hr />
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" component="div">
            What Other Questions Do You Have?{" "}
            <a href="mailto:contact@valleguadalupebaja.com">
              Message Us!
            </a>{" "}
            Have an amazing time, it’s an incredible place.
          </Typography>
        </Box>
      </Container>
    </Layout>
  );
};

export default InfoPage;

export const InfoPageQuery = graphql`
    query InfoPageQuery {
        hello: allFile(
            filter: { sourceInstanceName: { eq: "meet-the-founder" } }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData
                    }
                    name
                }
            }
        }
        allMarkdownRemark(filter: { frontmatter: { type: { eq: "info" } } }, sort: { fields: [fields___filename], order: ASC }) {
            edges {
                node {
                    htmlAst
                    frontmatter {
                        title
                        overline
                    }
                }
            }
        }
    }
`;
